<template>
  <div class='pa-6'>
    <v-sheet tile height='30' class='d-flex'>
      <h2>申込み状況</h2>
    </v-sheet>
    <v-container class='pb-0'>
      <v-row justify='center'>
        <p v-if='!reservations.length && !loading'>
          現在、申し込みは1件もありません。
        </p>
        <p v-if='!reservations.length && loading'>
          読み込み中です。少々お待ちください。
        </p>
        <v-col cols=12 lg=4 md=6 sm=6
          v-for='(reservation, key) in reservations'
          :key='key'
        >
          <v-card class='mb-8' max-width='500'>
            <v-card-title class='white--text blue light-2 text-body-2 pa-2'>
              申込み内容：{{ key +1 }}件目
              <v-spacer></v-spacer>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-card-text>
              <p class='mb-0 text--primary'>
                店舗
              </p>
              <v-text-field
                :value='reservation.shop.name'
                outlined
                dense
                readonly
              ></v-text-field>
              <p class='mb-0 text--primary'>
                予約日時
              </p>
              <v-text-field
                :value='changeFormat(reservation.date)'
                outlined
                dense
                readonly
              ></v-text-field>
              <p class='mb-0 text--primary'>
                種別
              </p>
              <v-text-field
                :value='reservation.type'
                outlined
                dense
                readonly
              ></v-text-field>
              <p class='mb-0 text--primary'>
                担当インストラクター
              </p>
              <v-text-field
                :value='reservation.instructor.name'
                outlined
                dense
                readonly
              ></v-text-field>
              <p class='mb-0 text--primary'>
                打席
              </p>
              <v-text-field
                :value='reservation.category'
                outlined
                dense
                readonly
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn color="red lighten-2" dark outlined small right @click='open(key)'>
                キャンセルする
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- dialog -->
    <v-dialog v-model='dialog' max-width='500px'>
      <v-form ref='form'>
        <v-card>
          <v-card-title>
            <span class="headline">予約のキャンセル</span>
          </v-card-title>
          <v-card-text>
            予約をキャンセルします。よろしいですか？
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-1" dark outlined @click='dialog = false' :disabled="loading">
              <v-icon class='pr-1'>mdi-close</v-icon>
              閉じる
            </v-btn>
            <v-btn color="red darken-2" dark outlined @click='delReservation()' :loading="loading">
              <v-icon class='pr-1'>mdi-trash-can-outline</v-icon>
              キャンセルする
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import moment from 'moment';
import util from '../util';

export default {
  data: () => ({
    reservations: [],
    selectIndex: -1,
    headers: [
      { text: '予約日時', sortable: false, value: 'start' },
      { text: '打席', sortable: false, value: 'category' },
      { text: '種別', sortable: false, value: 'type' },
      { text: 'インストラクター', sortable: false, value: 'instructor' },
    ],
    dialog: false,
    // snackbar
    snackbar: false,
    snackbarParam: {
      msg: '',
      color: '',
    },
    loading: false,
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item';
    },
    userInfo() {
      return this.$store.getters.user;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      // this.shops = await shop.get();
      // this.shops = await util.callCloudFunction('getShop');
      this.loading = true;
      this.reservations = await util.callCloudFunction('getHistoryFuture', { uid: this.userInfo.uid });
      this.loading = false;
    },
    open(index) {
      this.dialog = true;
      this.selectIndex = index;
    },
    changeFormat(date) {
      return moment(date).locale('ja').format('YYYY年MM月DD日(dd) HH:mm ~');
    },
    async delReservation() {
      this.loading = true;
      const param = {
        shop: this.reservations[this.selectIndex].shop,
        user: this.userInfo,
        date: this.reservations[this.selectIndex].date,
        type: this.reservations[this.selectIndex].type,
        category: this.reservations[this.selectIndex].category,
        reservationId: this.reservations[this.selectIndex].reservationId,
      };
      await util.callCloudFunction('delReservation', param);
      this.reservations = await util.callCloudFunction('getHistoryFuture', { uid: this.userInfo.uid });
      this.loading = false;
      this.dialog = false;
    },
  },
};
</script>
